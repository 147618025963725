import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import EditIcon from "@mui/icons-material/Edit";
import {
	Box,
	Button,
	DialogContent,
	Divider,
	IconButton,
	Link,
	Stack,
	Tab,
	Tabs,
	Tooltip,
	Typography,
} from "@mui/material";
import { GridRowId } from "@mui/x-data-grid-pro";
import { TabPanel } from "common/atoms/ct-tabs";
import { useFeatureFlagControl } from "hooks/useFeatureFlagControl";
import { FEATURES } from "hooks/useFeatureFlagControl/useFeatureFlagControl";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import { PendingWorkRequestsIcon } from "modules/AppBar/components/PendingWorkRequestsIcon";
import { RestrictToAllowedPathsPreviewDrawer } from "modules/add-to-templates/components/AddToTemplateDialog/RestrictToAllowedPathsPreviewDrawer";
import { DataGrid } from "modules/data-grid/components/data-grid";
import { RuleEngineTemplate } from "modules/matched-by-templates-drawer/types";
import { MatchedByTemplateType } from "modules/matched-by-templates/constants";
import { useScopeMetadata } from "modules/scope-metadata";
import { Scope } from "modules/scope-metadata/types";
import { AssetLoader } from "pages/asset/components/asset-loader";
import { Path, PathDirection } from "pages/paths/types";
import { useTagPolicyFacetStore } from "pages/tags/components/tag-policy-list/store";
import {
	BASE_INBOUND_PATH_COLUMNS,
	BASE_OUTBOUND_PATH_COLUMNS,
	BASE_PORT_COLUMNS,
	INBOUND_PATH_COLUMNS,
	OUTBOUND_PATH_COLUMNS,
	PORT_COLUMNS,
} from "pages/templates/components/template-data-grid/constants";
import {
	shouldSuggestRestrictPortToAllowedPathsForPaths,
	shouldSuggestRestrictPortToAllowedPathsForPorts,
} from "pages/templates/components/template-data-grid/helper";
import { useTemplateStore } from "pages/templates/store";
import {
	Template,
	TemplateFormProps,
	TemplatePath,
	TemplatePort,
	TemplateType as TemplateTypeEnum,
} from "pages/templates/types";
import { useEffect, useMemo, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { TemplateFormDrawer } from "../template-form-drawer";
import {
	PathRuleInt,
	TemplateFormMode,
} from "../template-form-drawer/components/template-form/types";
import { TemplateRemoveRule } from "../template-remove-rule";
import { TemplateType } from "../template-type";
import { TemplateTypeIcon } from "../template-type-icon";
import { useTemplateAPI } from "./hooks";

export enum TemplateViewTab {
	Ports = 0,
	Inbound = 1,
	Outbound = 2,
}

const pageSizeOptions = [5, 10, 15, 25, 50, 100, 200, 300];

interface TemplateDetailProps {
	templateId: string | undefined;
	viewOnly?: boolean;
	hideMetadata?: boolean;
	isLoading?: boolean;
	defaultTab?: TemplateViewTab;
	newRules?: PathRuleInt[];
	ruleEngineTemplate?: RuleEngineTemplate;
	columnMode?: string;
	type?: MatchedByTemplateType;
	isEmbeddedInDataGrid?: boolean;
}

export const TemplateDetail = ({
	templateId,
	viewOnly = false,
	hideMetadata = false,
	newRules,
	defaultTab = TemplateViewTab.Ports,
	ruleEngineTemplate,
	isLoading = false,
	columnMode = "",
	type,
	isEmbeddedInDataGrid = false,
}: TemplateDetailProps) => {
	const userPermissions = useUserPermissionsStore(
		state => state.userPermissions
	);
	const [
		isRestrictToAllowedPathsDrawerOpen,
		setIsRestrictToAllowedPathsDrawerOpen,
	] = useState<boolean>(false);
	const [canUpdateTemplate, setUpdateTemplatePerm] = useState<boolean>(false);
	const [selection, setSelection] = useState<Array<GridRowId>>([]);
	const [allowRemove, setAllowRemove] = useState<boolean>(false);
	const showRuleEngineTemplate = Boolean(
		Object.keys(ruleEngineTemplate ?? {}).length
	);

	useEffect(() => {
		let canUpdate: boolean = userPermissions.has("UPDATE_TEMPLATE");
		setUpdateTemplatePerm(canUpdate);
	}, [userPermissions]);

	const [selectedTab, setTab] = useState(defaultTab);
	const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
		setTab(newValue);
		setSelection([]);
		setIsRestrictToAllowedPathsDrawerOpen(false);
	};
	const [pageSize, setPageSize] = useState(100);
	const [portsPage, setPortsPage] = useState(0);
	const [inboundPathsPage, setInboundPathsPage] = useState(0);
	const [outboundPathsPage, setOutboundPathsPage] = useState(0);
	const [editDrawerVisibility, setEditDrawerVisibility] = useState(false);
	const [addDrawerVisibility, setAddDrawerVisibility] = useState(false);
	const requestAPIRefresh = useTemplateStore(state => state.requestAPIRefresh);

	const { data: assetMetadata } = useScopeMetadata({
		scope: Scope.TagBasedPolicy,
	});
	const tbpFacetUpdate = useTagPolicyFacetStore(state => state.updateMetadata);
	useEffect(() => {
		tbpFacetUpdate(assetMetadata);
	}, [assetMetadata, tbpFacetUpdate]);

	const onCloseAddDrawer = () => {
		setAddDrawerVisibility(false);
	};

	const openAddDrawer = () => {
		if (!data?.oobTemplate && !viewOnly) {
			setAddDrawerVisibility(true);
		}
	};

	const onCloseEditDrawer = () => {
		setEditDrawerVisibility(false);
	};

	const openEditDrawer = () => {
		if (!data?.oobTemplate) {
			setEditDrawerVisibility(true);
		}
	};

	const response = useTemplateAPI({ templateId });

	let { data, isFetching, refetch: requestRefetchTemplate }: any = response;

	const name = data?.templateName;
	const description = data?.templateDescription;

	useEffect(() => {
		setTab(defaultTab);
	}, [defaultTab]);

	const template: Template = useMemo(() => {
		if (showRuleEngineTemplate) {
			return ruleEngineTemplate;
		}

		if (!data && !viewOnly) {
			return;
		}

		if (name || description) {
			// fix for custom manipulation done below in update template function
		}
		let t = { ...data };

		if (newRules) {
			t.templatePaths = [...newRules, ...(t.templatePaths ?? [])];
		}

		// Display 0.0.0.0/0 ip as "any"
		t.templatePaths = t?.templatePaths?.map((obj: TemplatePath) => {
			if (obj.srcIp === "0.0.0.0/0") {
				obj.srcIp = "any";
			}
			obj.dstIp = obj?.dstIp?.map(ip => (ip === "0.0.0.0/0" ? "any" : ip));
			return obj;
		});

		t["templatePorts"] = (t?.templatePorts || []).map((obj: TemplatePort) => {
			if (t?.oobTemplate) {
				obj["oobTemplate"] = t.oobTemplate;
			}
			obj["templateId"] = templateId;
			return obj;
		}) as Array<TemplatePort>;

		t["inboundPaths"] = (t?.templatePaths || []).filter((obj: TemplatePath) => {
			if (t?.oobTemplate) {
				obj["oobTemplate"] = t.oobTemplate;
			}
			obj["templateId"] = templateId;
			return obj?.direction === "inbound";
		}) as Array<TemplatePath>;

		t["outboundPaths"] = (t?.templatePaths || []).filter(
			(obj: TemplatePath) => {
				if (t?.oobTemplate) {
					obj["oobTemplate"] = t.oobTemplate;
				}
				return obj?.direction === "outbound";
			}
		) as Array<TemplatePath>;

		return t;
	}, [
		data,
		viewOnly,
		name,
		description,
		newRules,
		templateId,
		ruleEngineTemplate,
		showRuleEngineTemplate,
	]);

	const hasServiceInboundPaths = useMemo(() => {
		return template?.inboundPaths?.some((row: TemplatePath) => {
			return Boolean(row?.method) || Boolean(row?.uri);
		});
	}, [template?.inboundPaths]);

	const hasServiceOutboundPaths = useMemo(() => {
		return template?.outboundPaths?.some((row: TemplatePath) => {
			return Boolean(row?.method) || Boolean(row?.uri);
		});
	}, [template?.outboundPaths]);

	const inboundPathcolumns = useMemo(() => {
		let filteredCols =
			!canUpdateTemplate || viewOnly
				? BASE_INBOUND_PATH_COLUMNS(columnMode)
				: INBOUND_PATH_COLUMNS(
						template?.templateType,
						template?.templateName,
						(template?.templatePorts as Array<TemplatePort>) ?? []
					);

		filteredCols = filteredCols.filter(column => {
			const removeColumns = [];

			if (!hasServiceInboundPaths) {
				removeColumns.push("api");
			}

			return !removeColumns.includes(column.field);
		});

		return filteredCols;
	}, [
		canUpdateTemplate,
		viewOnly,
		columnMode,
		template?.templateType,
		template?.templateName,
		template?.templatePorts,
		hasServiceInboundPaths,
	]);

	const { isFeatureEnabled: isProcessTemplateEnabled } = useFeatureFlagControl(
		FEATURES.PROCESS_BASED_TEMPLATE
	);

	const portColumns = useMemo(() => {
		let filteredCols =
			!canUpdateTemplate || viewOnly
				? BASE_PORT_COLUMNS({
						mode: columnMode,
						isProcessTemplateEnabled,
					})
				: PORT_COLUMNS(
						template?.templateType,
						template?.templateName ?? name,
						template?.inboundPaths ?? [],
						columnMode,
						isProcessTemplateEnabled
					);

		return filteredCols.filter(col => col.field !== "listenProcessNames");
	}, [
		canUpdateTemplate,
		name,
		template?.inboundPaths,
		template?.templateName,
		template?.templateType,
		viewOnly,
		columnMode,
		isProcessTemplateEnabled,
	]);

	const outboundPathcolumns = useMemo(() => {
		let filteredCols =
			!canUpdateTemplate || viewOnly
				? BASE_OUTBOUND_PATH_COLUMNS(columnMode)
				: OUTBOUND_PATH_COLUMNS(columnMode);

		filteredCols = filteredCols.filter(column => {
			const removeColumns = [];

			if (!hasServiceOutboundPaths) {
				removeColumns.push("api");
			}

			return !removeColumns.includes(column.field);
		});

		return filteredCols;
	}, [canUpdateTemplate, viewOnly, columnMode, hasServiceOutboundPaths]);

	const updateTemplateData = ({
		templateName,
		templateDescription,
		templateType,
	}: TemplateFormProps) => {
		requestAPIRefresh();
		requestRefetchTemplate();
		data["templateName"] = templateName
			? templateName?.trim()
			: template?.templateName;
		data["templateDescription"] = templateDescription
			? templateDescription.trim()
			: template?.templateDescription;
		data["templateType"] = templateType || template?.templateType;
	};

	const templatePorts = template?.templatePorts;
	const templatePaths = template?.templatePaths;

	let portRows = templatePorts;

	if (Array.isArray(templatePorts) && Array.isArray(templatePaths)) {
		portRows = templatePorts?.map(templatePort => {
			return {
				...templatePort,
				paths: templatePaths?.filter(
					templatePath =>
						templatePort.listenPort === templatePath.port &&
						templatePort.listenPortProtocol === templatePath.protocol &&
						templatePath.direction === PathDirection.Inbound
				),
			};
		});
	}

	const rules = useMemo(() => {
		switch (selectedTab) {
			case 0:
				return ((portRows as Array<TemplatePort>) ?? [])?.filter(
					(port: TemplatePort) =>
						selection.includes(port.lpId) &&
						shouldSuggestRestrictPortToAllowedPathsForPorts(
							port,
							template?.templateType ?? TemplateTypeEnum.ApplicationTemplate,
							template?.inboundPaths ?? []
						)
				);
			case 1:
				return (template?.inboundPaths || []).filter(
					(path: Path) =>
						selection.includes(path.channelHash) &&
						shouldSuggestRestrictPortToAllowedPathsForPaths(
							path,
							template?.templateType ?? TemplateTypeEnum.ApplicationTemplate,
							(template?.templatePorts as Array<TemplatePort>) ?? []
						)
				);
			default:
				return [];
		}
	}, [
		portRows,
		selectedTab,
		selection,
		template?.inboundPaths,
		template?.templatePorts,
		template?.templateType,
	]);

	const isRestrictToAllowedPathsButtonDisabled = useMemo(() => {
		switch (selectedTab) {
			case 0:
				return !selection.some(portId =>
					shouldSuggestRestrictPortToAllowedPathsForPorts(
						(portRows as TemplatePort[])?.find(
							port => port.lpId === (portId as string)
						) as TemplatePort,
						template?.templateType ?? TemplateTypeEnum.ApplicationTemplate,
						template?.inboundPaths ?? []
					)
				);
			case 1:
				return !selection.some(pathId =>
					shouldSuggestRestrictPortToAllowedPathsForPaths(
						template?.inboundPaths?.find(
							path => path.channelHash === pathId
						) as Path,
						template?.templateType ?? TemplateTypeEnum.ApplicationTemplate,
						template?.templatePorts as Array<TemplatePort>
					)
				);
			default:
				return true;
		}
	}, [
		portRows,
		selectedTab,
		selection,
		template?.inboundPaths,
		template?.templatePorts,
		template?.templateType,
	]);

	if ((isFetching && !viewOnly) || !template || isLoading) {
		return <AssetLoader />;
	}

	const renderBulkDeletionText = () => {
		switch (selectedTab) {
			case 0:
				return "DeleteSelectedPorts";
			case 1:
				return "DeleteSelectedInboundPaths";
			case 2:
				return "DeleteSelectedOutboundPaths";

			default:
				return "Delete selected ports";
		}
	};

	const onRestrictToAllowedPathsDrawerClose = () => {
		setIsRestrictToAllowedPathsDrawerOpen(false);
		setSelection([]);
	};

	const handleAllowRemove = () => {
		setAllowRemove(true);
	};

	const handleBulkRestrictToAllowedPaths = () => {
		if (selectedTab > 1) {
			return;
		}

		setIsRestrictToAllowedPathsDrawerOpen(true);
	};

	const createTabStyles = (disableDisplayCondition: boolean) => {
		return {
			display:
				showRuleEngineTemplate && disableDisplayCondition ? "none" : undefined,
		};
	};

	return (
		<>
			<DialogContent
				sx={{
					padding: hideMetadata ? "0 0" : viewOnly ? "16px 0 0" : "20px 24px",
					flex: 1,
					height: "100%",
					overflow: "hidden",
					minHeight: "300px",
				}}
			>
				<Stack
					sx={{ width: "100%", height: "100%" }}
					direction={"column"}
					justifyContent="flex-start"
					spacing={2}
				>
					{!hideMetadata && (
						<>
							<Stack
								sx={{
									padding: 2,
									width: "100%",
								}}
								spacing={2}
							>
								<Stack direction={"column"}>
									<Stack
										direction={"row"}
										justifyContent="space-between"
										alignItems={"center"}
										sx={{ width: "100%" }}
									>
										<Stack
											alignItems="flex-start"
											justifyContent={"center"}
											justifyItems={"center"}
											direction="row"
										>
											<Stack
												alignItems="center"
												justifyContent={"center"}
												justifyItems={"center"}
											>
												<Stack
													alignItems="center"
													justifyContent={"center"}
													justifyItems={"center"}
													sx={{ pr: 2, height: "32px" }}
												>
													<TemplateTypeIcon
														template={template}
														viewOnly={viewOnly}
													/>
												</Stack>
											</Stack>
											<Stack
												alignItems="flex-start"
												justifyContent={"center"}
												justifyItems={"flex-start"}
											>
												<Typography
													variant="h5"
													sx={{ textTransform: "capitalize" }}
												>
													{template?.templateName}
												</Typography>
												<Typography variant="body2" sx={{ pl: 0.5 }}>
													{template?.templateDescription}
												</Typography>
											</Stack>
										</Stack>

										{canUpdateTemplate && !viewOnly && (
											<Stack
												flex={1}
												alignItems="flex-end"
												justifyContent="center"
												justifyItems="flex-end"
												sx={{ mr: 5, position: "relative", top: "-12px" }}
											>
												<IconButton
													size="large"
													aria-label="Edit"
													onClick={() => openEditDrawer()}
													disableRipple={!!template?.oobTemplate}
													sx={{
														cursor: template?.oobTemplate
															? `not-allowed`
															: "pointer",
													}}
												>
													<Tooltip
														title={window.getCTTranslatedText(
															template?.oobTemplate
																? "System templates cannot be modified"
																: "Edit template"
														)}
													>
														<EditIcon
															fontSize="medium"
															color={
																template?.oobTemplate ? "disabled" : "action"
															}
														/>
													</Tooltip>
												</IconButton>
											</Stack>
										)}
									</Stack>
								</Stack>
							</Stack>
							<Stack
								alignItems={"center"}
								sx={{
									padding: 2,
								}}
								spacing={1}
								direction="row"
							>
								<Stack
									direction={"row"}
									justifyContent="flex-start"
									alignItems={"center"}
									spacing={2}
								>
									<Typography variant="subtitle1">
										<b>{window.getCTTranslatedText("Type")}</b>:
									</Typography>
									<Stack style={{ marginTop: "3px" }}>
										<TemplateType viewOnly={true} template={template} />
									</Stack>
								</Stack>
								{!template?.oobTemplate &&
									!viewOnly &&
									userPermissions.has("LIST_EVENT") && (
										<Stack
											flex={1}
											alignItems="center"
											justifyContent={"flex-end"}
											sx={{ mt: 0.5 }}
											direction="row"
										>
											{templateId && (
												<Stack mr={2}>
													<PendingWorkRequestsIcon resourceID={templateId} />
												</Stack>
											)}
											<Stack alignItems="center">
												<Link
													typography={"body2"}
													component={RouterLink}
													to={`/monitoring?tab=audit_logs&filters=resourceid=${template.templateId}`}
												>
													{window.getCTTranslatedText("View History")}
												</Link>
											</Stack>
										</Stack>
									)}
							</Stack>
							<Divider />
						</>
					)}
					{userPermissions.has("UPDATE_TEMPLATE") && editDrawerVisibility && (
						<TemplateFormDrawer
							template={template as Template}
							isOpen={editDrawerVisibility}
							onClose={onCloseEditDrawer}
							updateTemplateData={updateTemplateData}
							title={"Edit template details"}
							btnTitle={"Update"}
							mode={TemplateFormMode.EDIT}
						/>
					)}
					{userPermissions.has("UPDATE_TEMPLATE") && addDrawerVisibility && (
						<TemplateFormDrawer
							template={template as Template}
							isOpen={addDrawerVisibility}
							onClose={onCloseAddDrawer}
							updateTemplateData={updateTemplateData}
							title={
								template?.templateType === TemplateTypeEnum.ApplicationTemplate
									? "Add Ports and Paths"
									: "Add Ports"
							}
							btnTitle={"add"}
							mode={TemplateFormMode.ADD}
						/>
					)}
					<Stack
						alignItems={"flex-start"}
						sx={{
							padding: isEmbeddedInDataGrid ? 2 : viewOnly ? 0 : 2,
							flex: 1,
							background: theme => theme.palette.background.paper,
							borderRadius: 1,
							overflow: "hidden",
						}}
						spacing={4}
					>
						<Stack
							sx={{
								width: "100%",
								flex: 1,
								overflow: "hidden",
								"& .MuiDataGrid-row.new-rule": {
									backgroundColor: theme => theme.palette.action.selected,
								},
							}}
						>
							<Box sx={{ borderBottom: 1, borderColor: "divider" }}>
								<Stack direction="row" sx={{ width: "100%", mb: 1 }}>
									<Stack>
										<Tabs value={selectedTab} onChange={handleTabChange}>
											<Tab
												label={window.getCTTranslatedText("Ports")}
												sx={createTabStyles(
													!(portRows as Array<TemplatePort>)?.length
												)}
											/>
											{template?.templateType !==
												TemplateTypeEnum.BlockTemplate && (
												<Tab
													label={window.getCTTranslatedText("Inbound Paths")}
													sx={createTabStyles(
														!template?.inboundPaths?.length ||
															type === MatchedByTemplateType.PORT
													)}
												/>
											)}
											{template?.templateType !==
												TemplateTypeEnum.BlockTemplate && (
												<Tab
													label={window.getCTTranslatedText("Outbound Paths")}
													sx={createTabStyles(
														!template?.outboundPaths?.length ||
															type === MatchedByTemplateType.PORT
													)}
												/>
											)}
										</Tabs>
									</Stack>
									<Stack flex={1}>
										{userPermissions.has("UPDATE_TEMPLATE") &&
											!template?.oobTemplate &&
											!viewOnly && (
												<Stack
													flex={1}
													alignItems="center"
													justifyContent={"flex-end"}
													sx={{ mt: 0.5 }}
													direction="row"
												>
													{selection?.length > 0 ? (
														<Stack
															alignItems="center"
															ml={2}
															spacing={1}
															direction="row"
														>
															{
																<Button
																	variant="outlined"
																	color="primary"
																	onClick={handleBulkRestrictToAllowedPaths}
																	disabled={
																		isRestrictToAllowedPathsButtonDisabled
																	}
																>
																	{window.getCTTranslatedText(
																		"restrictPortsToAllowedPaths"
																	)}
																</Button>
															}
															<Button
																variant="outlined"
																color="error"
																onClick={handleAllowRemove}
															>
																{window.getCTTranslatedText(
																	renderBulkDeletionText()
																)}
															</Button>
														</Stack>
													) : (
														<Stack alignItems="center">
															<Tooltip
																title={window.getCTTranslatedText(
																	template?.oobTemplate
																		? "System templates cannot be modified"
																		: template?.templateType ===
																			  TemplateTypeEnum.ApplicationTemplate
																			? "Add Ports and Paths to template"
																			: "Add Ports to template"
																)}
															>
																<Button
																	variant="text"
																	startIcon={<AddCircleOutlineIcon />}
																	color="primary"
																	onClick={() => openAddDrawer()}
																>
																	{`${window.getCTTranslatedText(
																		"AddPortsAndPaths",
																		{
																			showPathsLabel:
																				window.getCTTranslatedText(
																					template?.templateType ===
																						TemplateTypeEnum.ApplicationTemplate
																						? "and Paths"
																						: ""
																				),
																		}
																	)}`}
																</Button>
															</Tooltip>
														</Stack>
													)}
												</Stack>
											)}
									</Stack>
								</Stack>
							</Box>
							<TabPanel
								style={{ flex: 1, overflow: "hidden" }}
								value={selectedTab}
								index={0}
							>
								<DataGrid
									loading={isFetching}
									rows={(portRows as Array<TemplatePort>) ?? []}
									rowCount={Array.isArray(portRows) ? portRows?.length : 0}
									rowHeight={64}
									columns={portColumns}
									getRowId={({ lpId }: any) => lpId}
									pageSize={pageSize}
									onPageSizeChange={setPageSize}
									page={portsPage}
									onPageChange={setPortsPage}
									pagination
									paginationMode="client"
									disableColumnMenu
									disableRowSelectionOnClick
									checkboxSelection={!viewOnly && !template?.oobTemplate}
									rowSelectionModel={selection}
									onRowSelectionModelChange={selectionModel => {
										setSelection(selectionModel);
									}}
									pageSizeOptions={pageSizeOptions}
								/>
							</TabPanel>
							<TabPanel
								style={{ flex: 1, overflow: "hidden" }}
								value={selectedTab}
								index={1}
							>
								<DataGrid
									getRowClassName={params => {
										if (params.row.channelHash.startsWith("new-rule")) {
											return "new-rule";
										}
										return "";
									}}
									loading={isFetching}
									rows={template?.inboundPaths || []}
									rowCount={template?.inboundPaths?.length ?? 0}
									rowHeight={64}
									columns={inboundPathcolumns}
									getRowId={({ channelHash }: TemplatePath) => channelHash}
									pageSize={pageSize}
									onPageSizeChange={setPageSize}
									page={inboundPathsPage}
									onPageChange={setInboundPathsPage}
									pagination
									paginationMode="client"
									disableColumnMenu
									disableRowSelectionOnClick
									checkboxSelection={!viewOnly && !template?.oobTemplate}
									rowSelectionModel={selection}
									onRowSelectionModelChange={selectionModel => {
										setSelection(selectionModel);
									}}
									pageSizeOptions={pageSizeOptions}
								/>
							</TabPanel>
							<TabPanel
								style={{ flex: 1, overflow: "hidden" }}
								value={selectedTab}
								index={2}
							>
								<DataGrid
									getRowClassName={params => {
										if (params.row.channelHash.startsWith("new-rule")) {
											return "new-rule";
										}
										return "";
									}}
									loading={isFetching}
									rows={template?.outboundPaths || []}
									rowCount={template?.outboundPaths?.length ?? 0}
									rowHeight={64}
									columns={outboundPathcolumns}
									getRowId={({ channelHash }: TemplatePath) => channelHash}
									pageSize={pageSize}
									page={outboundPathsPage}
									onPageChange={setOutboundPathsPage}
									onPageSizeChange={setPageSize}
									pagination
									paginationMode="client"
									disableColumnMenu
									disableRowSelectionOnClick
									checkboxSelection={!viewOnly && !template?.oobTemplate}
									rowSelectionModel={selection}
									onRowSelectionModelChange={selectionModel => {
										setSelection(selectionModel);
									}}
									pageSizeOptions={pageSizeOptions}
								/>
							</TabPanel>
						</Stack>
					</Stack>
				</Stack>
			</DialogContent>

			{allowRemove && (
				<TemplateRemoveRule
					rule={selection}
					page={selectedTab === 0 ? "port" : "path"}
					allowRemove={allowRemove}
					handleAllowRemove={setAllowRemove}
					templateID={templateId}
				/>
			)}

			<RestrictToAllowedPathsPreviewDrawer
				isOpen={isRestrictToAllowedPathsDrawerOpen}
				onClose={onRestrictToAllowedPathsDrawerClose}
				rules={rules}
				allRules={template?.inboundPaths ?? []}
				ruleType={selectedTab === 0 ? "PORT" : "PATH"}
				templateID={templateId}
				title={template?.templateName}
				templateType={template.templateType}
			/>
		</>
	);
};
