import {
	Box,
	Chip,
	LinearProgress,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import {
	DataGridPro,
	GRID_CHECKBOX_SELECTION_COL_DEF,
	GridColDef,
	GridColumnMenu,
	GridColumnMenuHideItem,
	GridColumnMenuPinningItem,
	GridColumnMenuProps,
	GridColumnVisibilityModel,
	GridPinnedColumns,
} from "@mui/x-data-grid-pro";
import { useUserPreferencesStore } from "common/store/useUserPreferenceStore";
import { useFeatureFlagControl } from "hooks/useFeatureFlagControl";
import { FEATURES } from "hooks/useFeatureFlagControl/useFeatureFlagControl";
import { ExportCSV } from "modules/export-csv/ExportCSV";
import { useCallback, useEffect, useMemo } from "react";
import { DataGridProps } from "./types";

interface CustomCommonMenuProps extends GridColumnMenuProps {
	showFilter?: boolean;
	showMenuItems?: boolean;
}

function CustomColumnMenu(props: CustomCommonMenuProps) {
	return (
		<GridColumnMenu
			{...props}
			style={
				{
					ul: {
						display: "none",
					},
				} as any
			}
			slots={{
				columnMenuFilterItem: null,
				columnMenuColumnsItem: props?.showMenuItems
					? props => <GridColumnMenuHideItem {...props} />
					: null,
				columnMenuPinningItem: props?.showMenuItems
					? props => <GridColumnMenuPinningItem {...props} />
					: null,
			}}
		/>
	);
}

export function DataGrid<DataType>(props: DataGridProps<DataType>) {
	const theme = useTheme();
	let { isFeatureEnabled: COLUMN_CONTROLS_ENABLED } = useFeatureFlagControl(
		FEATURES.DATA_GRID_COLUMNS_CONTROLS
	);

	const setDataGridPreferences = useUserPreferencesStore(
		state => state.setDataGridPreferences
	);
	const dataGridPreferencesObject = useUserPreferencesStore(
		state => state.dataGridPreferences
	);
	const dataGridPreferenceKey = props?.metadata?.scope ?? "";
	const dataGridPreferences = useMemo(
		() => dataGridPreferencesObject?.[dataGridPreferenceKey],
		[dataGridPreferencesObject, dataGridPreferenceKey]
	);

	const hiddenColumns = dataGridPreferences?.hiddenColumns;
	const pinnedColumns = dataGridPreferences?.pinnedColumns;

	const showDataGridHiddenColumns = Object.values(hiddenColumns ?? {}).includes(
		false
	);

	const setHiddenColumns = useCallback(
		(u?: GridColumnVisibilityModel) => {
			if (!dataGridPreferenceKey) {
				return false;
			}
			setDataGridPreferences(dataGridPreferenceKey, {
				hiddenColumns: u,
			});
		},
		[dataGridPreferenceKey, setDataGridPreferences]
	);

	const setPinnedColumns = useCallback(
		(pinnedColumns: GridPinnedColumns) => {
			if (!dataGridPreferenceKey) {
				return false;
			}
			setDataGridPreferences(dataGridPreferenceKey, {
				pinnedColumns: pinnedColumns,
			});
		},
		[dataGridPreferenceKey, setDataGridPreferences]
	);

	useEffect(() => {
		if (!dataGridPreferenceKey) {
			return;
		}
		if (!hiddenColumns && props.defaultHiddenColumns) {
			setHiddenColumns(props.defaultHiddenColumns);
		}
	}, [
		hiddenColumns,
		props.defaultHiddenColumns,
		setHiddenColumns,
		dataGridPreferenceKey,
	]);

	useEffect(() => {
		if (!dataGridPreferenceKey) {
			return;
		}
		if (!pinnedColumns && props.defaultPinnedColumns) {
			setPinnedColumns(props.defaultPinnedColumns);
		}
	}, [
		pinnedColumns,
		props.defaultPinnedColumns,
		setPinnedColumns,
		dataGridPreferenceKey,
	]);

	const DataGridCustomStyles = {
		...DataGridStyles,
		"& .hideRightSeparator > .MuiDataGrid-columnSeparator": {
			display: "none",
		},
		"& .MuiDataGrid-row:hover": {
			cursor: `${
				props.onRowClick || props.onCellClick ? "pointer" : "default"
			}`,
		},
		"& .MuiDataGrid-pinnedColumns": {
			backgroundColor: theme.palette.background.paper,
			backgroundImage:
				theme.palette.mode === "light"
					? "unset"
					: `linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))`,
			boxShadow: "unset",
			"&.MuiDataGrid-pinnedColumns--left": {
				borderRight:
					theme.palette.mode === "dark"
						? `2px solid ${theme.palette.custom.tableBdrClrDark}`
						: `2px solid ${grey[300]}`,
			},
			"&.MuiDataGrid-pinnedColumns--right": {
				borderLeft:
					theme.palette.mode === "dark"
						? `2px solid ${theme.palette.custom.tableBdrClrDark}`
						: `2px solid ${grey[300]}`,
			},
		},
		"& .MuiDataGrid-pinnedColumnHeaders": {
			backgroundColor:
				theme.palette.mode === "dark"
					? theme.palette.background.paper
					: grey[100],
			backgroundImage:
				theme.palette.mode === "light"
					? "unset"
					: `linear-gradient(rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12))`,
			boxShadow: "unset",
			"&.MuiDataGrid-pinnedColumnHeaders--left": {
				borderRight:
					theme.palette.mode === "dark"
						? `2px solid ${theme.palette.custom.tableBdrClrDark}`
						: `2px solid ${grey[300]}`,
			},
			"&.MuiDataGrid-pinnedColumnHeaders--right": {
				borderLeft:
					theme.palette.mode === "dark"
						? `2px solid ${theme.palette.custom.tableBdrClrDark}`
						: `2px solid ${grey[300]}`,
			},
		},
		"& .MuiDataGrid-columnsContainer": {
			backgroundColor: theme.palette.background.paper,
		},
		"& .MuiDataGrid-detailPanel": {
			backgroundColor: theme.palette.background.paper,
		},
		"& .MuiDataGrid-columnHeaders": {
			zIndex: 0,
			backgroundColor:
				theme.palette.mode === "dark"
					? theme.palette.background.paper
					: grey[100],
			backgroundImage:
				theme.palette.mode === "light"
					? "unset"
					: `linear-gradient(rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12))`,
		},
		// Fix NoRowsOverlay height when DataGrid is used within a Accordion
		".MuiDataGrid-virtualScroller": {
			minHeight: "100px",
		},
		"& .MuiDataGrid-overlayWrapperInner": {
			position: "relative",
		},
		// To hide the badge on top of the sort icon
		"& .MuiDataGrid-columnHeader--sortable .MuiDataGrid-iconButtonContainer .MuiBadge-badge":
			{
				display: "none",
			},
	};

	let columns = useMemo(() => {
		if (!props.columns) {
			return [];
		}

		return props.columns.map(column => {
			column = { ...column };
			column.headerName = window.getCTTranslatedText(
				(props.metadata?.columns?.[column.field]?.displayName ||
					column.headerName) ??
					""
			);

			if (
				!dataGridPreferenceKey &&
				column.sortable === false &&
				!pinnedColumns
			) {
				column.disableColumnMenu = true;
			}

			return column;
		});
	}, [props.columns, props.metadata, dataGridPreferenceKey, pinnedColumns]);

	const paginationModel =
		props.paginationMode === "client"
			? undefined
			: {
					page: props.page ?? 0,
					pageSize: props.pageSize ?? 100,
				};

	const pinnedDataGridColumns = useMemo(() => {
		if (!dataGridPreferenceKey) {
			return pinnedColumns;
		}

		const isCheckboxSelectionPinned = pinnedColumns?.left?.includes(
			GRID_CHECKBOX_SELECTION_COL_DEF.field
		);

		const isActionColumnPinned =
			pinnedColumns?.right?.includes("action") ||
			pinnedColumns?.right?.includes("actions") ||
			pinnedColumns?.right?.includes("comment");

		if (isCheckboxSelectionPinned && isActionColumnPinned) {
			return pinnedColumns;
		}

		if (isActionColumnPinned) {
			return {
				left: [
					GRID_CHECKBOX_SELECTION_COL_DEF.field,
					...(pinnedColumns?.left ?? []),
				],
				right: pinnedColumns?.right,
			};
		}

		if (isCheckboxSelectionPinned) {
			return {
				left: pinnedColumns?.left,
				right: [
					...(pinnedColumns?.right ?? []),
					"action",
					"actions",
					"comment",
				],
			};
		}

		const isCheckboxSelectionPresent = columns.some(
			column => column.field === GRID_CHECKBOX_SELECTION_COL_DEF.field
		);

		const isDefaultPinnedColumnsPresent =
			props.defaultPinnedColumns?.left?.includes(
				GRID_CHECKBOX_SELECTION_COL_DEF.field
			);

		const isActionsColumnPresent = columns.some(
			column =>
				column.field === "action" ||
				column.field === "actions" ||
				column.field === "comment"
		);

		const isDefaultActionsColumnsPresent =
			props.defaultPinnedColumns?.right?.includes("action") ||
			props.defaultPinnedColumns?.right?.includes("actions") ||
			props.defaultPinnedColumns?.right?.includes("comment");

		if (
			!isCheckboxSelectionPresent &&
			!isDefaultPinnedColumnsPresent &&
			!isDefaultActionsColumnsPresent &&
			!isActionsColumnPresent
		) {
			return pinnedColumns;
		}

		return {
			left: [
				GRID_CHECKBOX_SELECTION_COL_DEF.field,
				...(pinnedColumns?.left ?? []),
			],
			right: [...(pinnedColumns?.right ?? []), "action", "actions", "comment"],
		};
	}, [
		columns,
		dataGridPreferenceKey,
		pinnedColumns,
		props.defaultPinnedColumns,
	]);

	const shouldShowHiddenColumns =
		showDataGridHiddenColumns && COLUMN_CONTROLS_ENABLED;

	const dataGridHiddenColumns = (
		<DataGridHiddenColumns
			hiddenColumns={hiddenColumns}
			setHiddenColumns={setHiddenColumns}
			columns={columns}
		/>
	);

	return (
		<>
			{props.renderToolbar
				? props.renderToolbar(
						shouldShowHiddenColumns ? dataGridHiddenColumns : undefined
					)
				: shouldShowHiddenColumns && dataGridHiddenColumns}
			<Box
				sx={{
					height: props.renderToolbar ? "calc(100% - 54px)" : "100%",
					width: "100%",
					position: "relative",
				}}
			>
				{props?.triggerExportAsCsv && <ExportCSV {...props} />}
				<DataGridPro
					sx={{ ...DataGridCustomStyles }}
					{...props}
					pinnedColumns={
						COLUMN_CONTROLS_ENABLED && pinnedDataGridColumns
							? pinnedDataGridColumns
							: undefined
					}
					onPinnedColumnsChange={(cols: GridPinnedColumns) => {
						cols.right = cols.right?.sort((a, b) => {
							const isFixed = (c: string) => {
								return (
									c === "comment" ||
									c === "metadataicons" ||
									c?.includes("action") ||
									c?.includes("diagnostics")
								);
							};
							if (isFixed(a)) {
								return 1;
							}
							if (isFixed(b)) {
								return -1;
							}
							return 0;
						});

						setPinnedColumns(cols);
					}}
					slots={{
						noRowsOverlay: () => NoRowsOverlayComp(props.noRowsOverlayText),
						...props.slots,
						loadingOverlay: LinearProgress,
						columnMenu: colProps => (
							<CustomColumnMenu
								showFilter={props.showSearchBar}
								showMenuItems={Boolean(dataGridPreferenceKey)}
								{...colProps}
							/>
						),
					}}
					rows={props.rows || []}
					columns={columns}
					columnVisibilityModel={hiddenColumns ?? {}}
					onColumnVisibilityModelChange={(
						params: GridColumnVisibilityModel
					) => {
						setHiddenColumns({ ...params });
					}}
					pageSizeOptions={props.pageSizeOptions ?? [5, 10, 15, 25, 50, 100]}
					disableColumnMenu={!COLUMN_CONTROLS_ENABLED}
					disableRowSelectionOnClick
					loading={
						props.isLoading ||
						props?.mutation?.isLoading ||
						props?.mutation?.isIdle ||
						props.rows === undefined
					}
					paginationModel={paginationModel}
					onPaginationModelChange={model => {
						props.onPageChange?.(model.page);
						props.onPageSizeChange?.(model.pageSize);
					}}
					onSortModelChange={model => {
						if (model?.length > 0 && props.onSortChange) {
							props.onSortChange(
								model.map(value => {
									return {
										field: value.field,
										order: value.sort,
									};
								})
							);
						}
					}}
				/>
			</Box>
		</>
	);
}

interface DataGridHiddenColumnsProps {
	hiddenColumns?: GridColumnVisibilityModel;
	setHiddenColumns: (u?: GridColumnVisibilityModel) => void;
	columns: GridColDef[];
}

const DataGridHiddenColumns = ({
	hiddenColumns,
	setHiddenColumns,
	columns,
}: DataGridHiddenColumnsProps) => {
	return (
		<Stack direction={"row"} alignItems={"center"} sx={{ width: "100%" }}>
			<Typography
				variant="body2"
				sx={{ textAlign: "center", p: 1, flexShrink: 0 }}
			>
				{window.getCTTranslatedText("Hidden Columns")}:
			</Typography>
			{Object.entries(hiddenColumns ?? {})?.map(
				([key, value]) =>
					!value && (
						<Stack key={key} direction={"row"} sx={{ px: 1, py: 1.5 }}>
							<Chip
								label={columns.find(col => col.field === key)?.headerName}
								onDelete={() => {
									const oldHiddenCols = { ...hiddenColumns };
									delete oldHiddenCols[key];
									setHiddenColumns({ ...oldHiddenCols });
								}}
							/>
						</Stack>
					)
			)}
		</Stack>
	);
};

export const DataGridStyles = {
	"& .MuiDataGrid-columnHeaderTitle": {
		lineHeight: 1,
		whiteSpace: "pre-wrap",
		userSelect: "all !important",
		pointerEvents: "all !important",
	},
	"& .MuiDataGrid-cell:focus": {
		outline: 0,
	},
	"& .MuiDataGrid-cell:focus-within": {
		outline: 0,
	},
};

export const NoRowsOverlayComp = (text?: string) => {
	return (
		<Stack
			alignItems={"center"}
			justifyContent="center"
			sx={{
				zIndex: 100,
				width: "100%",
				height: "100%",
				position: "relative",
			}}
		>
			<Typography variant="body2">
				{window.getCTTranslatedText(text ?? "No results")}
			</Typography>
		</Stack>
	);
};
